'use strict';

/**
 * library
 */
import TweenLite from 'gsap/TweenLite';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

/**
 * module
 */

/**
 * anchor
 */
export const anchor = (() => {
    /**
     * init
     */
    const init = () => {
        // #を含むアンカーの場合
        $('a[href*="#"]').on('click', event => {
            const id = $(event.currentTarget).attr('href');
            const href = $(event.currentTarget).prop('href');

            if (compareCurrentDir(href)) {
                // ターゲットidが現在のページ内を指定していたら
                moveTo(event, id);
            }
        });

        // #から始まるアンカーの場合
        $('a[href^="#"]').on('click', event => {
            const $this = $(event.currentTarget);
            moveTo(event, $this.attr('href'));
        });
    };

    /**
     * moveTo
     */
    const moveTo = (event, id) => {
        const $target = $(id.substr(id.indexOf('#')));
        const offset = $('.layout-header').height();

        event.preventDefault();
        TweenLite.to(window, 0.8, {
            scrollTo: {
                y: $target.offset().top - offset,
                autoKill: false // この設定をしないとiPhoneで途中で止まる
            }
        });
    };

    /**
     * compareCurrentDir
     */
    const compareCurrentDir = href => {
        const path = href.substring(0, href.lastIndexOf('#'));
        let location = window.location.href;

        if (location.lastIndexOf('#') >= 0) {
            location = location.substring(0, location.lastIndexOf('#'));
        }

        if (path === location) {
            return true;
        } else {
            return false;
        }
    };

    // public
    return {
        init: init
    };
})();
