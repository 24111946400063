'use strict';

export const nav = (() => {
    const $header = $('.layout-header');
    const $nav = $header.find('.nav');

    const show = () => {
        $('body').addClass('is-show-nav');
    };

    const hide = () => {
        $('body').removeClass('is-show-nav');
    };

    const toggle = () => {
        $('body').toggleClass('is-show-nav');
    };

    const alignHeight = () => {
        $nav.outerHeight($(window).height() * 1.2);
    };

    const init = () => {
        $('.js-toggle-nav').on('click', () => {
            toggle();
        });

        $('.js-show-nav').on('click', () => {
            show();
        });

        $('.js-hide-nav').on('click', () => {
            hide();
        });
    };

    return {
        init: init
    };
})();
