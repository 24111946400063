'use strict';

export const modal = (() => {
    const show = event => {
        const $this = $(event.currentTarget);
        const id = $this.data('modal');

        $('body').addClass('is-show-modal is-show-' + id);
    };

    const hide = event => {
        const $this = $(event.currentTarget);
        const id = $this.data('modal');

        $('body').removeClass('is-show-modal is-show-' + id);

        if (!$('body').hasClass('is-start-blink')) {
            $('body').addClass('is-start-blink');
        }
    };

    const resize = function() {
        let size = {};
        const w = ($(window).height() * 0.9) / 0.5625;

        if (w > $(window).width()) {
            size = {
                w: $(window).width() * 0.9,
                h: $(window).width() * 0.9 * 0.5625
            };
        } else {
            size = {
                w: ($(window).height() * 0.9) / 0.5625,
                h: $(window).height() * 0.9
            };
        }

        const $inner = $('.layout-modal--trailer').find('.inner');

        $inner.width(size.w);
        $inner.innerHeight(size.h);
    };

    const init = () => {
        resize();

        $(window).on('resize', function() {
            setTimeout(function() {
                resize();
            }, 100);
        });

        $('.js-show-modal').on('click', event => {
            show(event);
        });

        $('.js-hide-modal').on('click', event => {
            hide(event);
        });
    };

    return {
        init: init
    };
})();
