'use strict';

/**
 * library
 */
import Barba from 'barba.js';
import TweenLite from 'gsap/TweenLite';
import CSSPlugin from 'gsap/CSSPlugin';

/**
 * module
 */
import { page } from './_page';

/**
 * pjax
 */
export const pjax = (() => {
    const transition = Barba.BaseTransition.extend({
        start: function() {
            const that = this;

            Promise.all([this.newContainerLoading, this.out()]).then(this.in.bind(this));
        },
        out: function() {
            const that = this;
            const $old = $(this.oldContainer);

            if ($('body').hasClass('is-show-nav')) {
                $('body').removeClass('is-show-nav');

                setTimeout(() => {
                    $('body')
                        .removeClass('is-changed')
                        .addClass('is-transition-start');
                }, 200);

                return new Promise(function(resolve, reject) {
                    TweenLite.to($old, 0.2, {
                        opacity: 0,
                        delay: 0.4,
                        onComplete: () => {
                            $old.hide();
                            resolve();
                        }
                    });
                });
            } else {
                $('body')
                    .removeClass('is-changed')
                    .addClass('is-transition-start');

                return new Promise(function(resolve, reject) {
                    TweenLite.to($old, 0.2, {
                        opacity: 0,
                        delay: 0.2,
                        onComplete: () => {
                            $old.hide();
                            resolve();
                        }
                    });
                });
            }
        },
        in: function() {
            const that = this;
            const $new = $(this.newContainer);

            $(window).scrollTop(0);

            TweenLite.set($new, {
                opacity: 0,
                visibility: 'visible'
            });

            TweenLite.to($new, 0.2, {
                opacity: 1,
                delay: 0.2,
                onComplete: () => {
                    $('body').addClass('is-transition-end is-changed');
                    setTimeout(() => {
                        $('body').removeClass('is-transition-start is-transition-end');
                        $new.find('.layout-content__inner').addClass('is-start');
                        that.done();
                    }, 500);
                }
            });
        }
    });

    const home = Barba.BaseView.extend({
        namespace: 'home',
        onEnter: () => {
            console.log('home：load start');
            $('body').addClass('is-home');

            if (!$('.nav-global__item--home').hasClass('is-active')) {
                $('.nav-global__item.is-active').removeClass('is-active');
                $('.nav-global__item--home').addClass('is-active');
            }
        },
        onEnterCompleted: () => {
            console.log('home：transition-in end');
            page.home();
        },
        onLeave: () => {
            console.log('-- move --');
            console.log('home：transition-out start');
            $('body').removeClass('is-home');
        },
        onLeaveCompleted: () => {
            console.log('home：container remove');
            $('body').removeClass('is-start-blink');
        }
    });

    const introduction = Barba.BaseView.extend({
        namespace: 'introduction',
        onEnter: () => {
            console.log('introduction：load start');
            $('body').removeClass('is-show-header');

            if (!$('.nav-global__item--introduction').hasClass('is-active')) {
                $('.nav-global__item.is-active').removeClass('is-active');
                $('.nav-global__item--introduction').addClass('is-active');
            }
        },
        onEnterCompleted: () => {
            console.log('introduction：transition-in end');
            page.introduction();
        },
        onLeave: () => {
            console.log('-- move --');
            console.log('introduction：transition-out start');
        },
        onLeaveCompleted: () => {
            console.log('introduction：container remove');
        }
    });

    const cast = Barba.BaseView.extend({
        namespace: 'cast',
        onEnter: () => {
            console.log('cast：load start');
            $('body').removeClass('is-show-header');

            if (!$('.nav-global__item--cast').hasClass('is-active')) {
                $('.nav-global__item.is-active').removeClass('is-active');
                $('.nav-global__item--cast').addClass('is-active');
            }
        },
        onEnterCompleted: () => {
            console.log('cast：transition-in end');
            page.cast();
        },
        onLeave: () => {
            console.log('-- move --');
            console.log('cast：transition-out start');
        },
        onLeaveCompleted: () => {
            console.log('cast：container remove');
        }
    });

    const news = Barba.BaseView.extend({
        namespace: 'news',
        onEnter: () => {
            console.log('news：load start');
            $('body').removeClass('is-show-header');

            if (!$('.nav-global__item--news').hasClass('is-active')) {
                $('.nav-global__item.is-active').removeClass('is-active');
                $('.nav-global__item--news').addClass('is-active');
            }
        },
        onEnterCompleted: () => {
            console.log('news：transition-in end');
            page.news();
        },
        onLeave: () => {
            console.log('-- move --');
            console.log('news：transition-out start');
        },
        onLeaveCompleted: () => {
            console.log('news：container remove');
        }
    });

    const pokemon = Barba.BaseView.extend({
        namespace: 'pokemon',
        onEnter: () => {
            console.log('pokemon：load start');
            $('body').removeClass('is-show-header');

            if (!$('.nav-global__item--pokemon').hasClass('is-active')) {
                $('.nav-global__item.is-active').removeClass('is-active');
                $('.nav-global__item--pokemon').addClass('is-active');
            }
        },
        onEnterCompleted: () => {
            console.log('pokemon：transition-in end');
            page.pokemon();
        },
        onLeave: () => {
            console.log('-- move --');
            console.log('pokemon：transition-out start');
        },
        onLeaveCompleted: () => {
            console.log('pokemon：container remove');
        }
    });

    const music = Barba.BaseView.extend({
        namespace: 'music',
        onEnter: () => {
            console.log('music：load start');
            $('body').removeClass('is-show-header');

            if (!$('.nav-global__item--music').hasClass('is-active')) {
                $('.nav-global__item.is-active').removeClass('is-active');
                $('.nav-global__item--music').addClass('is-active');
            }
        },
        onEnterCompleted: () => {
            console.log('music：transition-in end');
            page.music();
        },
        onLeave: () => {
            console.log('-- move --');
            console.log('music：transition-out start');
        },
        onLeaveCompleted: () => {
            console.log('music：container remove');
        }
    });

    const pronote = Barba.BaseView.extend({
        namespace: 'pronote',
        onEnter: () => {
            console.log('pronote：load start');
            $('body').removeClass('is-show-header');

            if (!$('.nav-global__item--pronote').hasClass('is-active')) {
                $('.nav-global__item.is-active').removeClass('is-active');
                $('.nav-global__item--pronote').addClass('is-active');
            }
        },
        onEnterCompleted: () => {
            console.log('pronote：transition-in end');
            page.pronote();
        },
        onLeave: () => {
            console.log('-- move --');
            console.log('pronote：transition-out start');
        },
        onLeaveCompleted: () => {
            console.log('pronote：container remove');
        }
    });

    const init = () => {
        Barba.Pjax.Dom.wrapperId = 'pjax';
        Barba.Pjax.Dom.containerClass = 'layout-content__outer';

        Barba.Pjax.getTransition = () => {
            return transition;
        };

        Barba.Dispatcher.on('initStateChange', function() {
            if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
                ga('send', 'pageview', location.pathname);
            }
            if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) {
                gtag('config', 'UA-90194236-2', { page_path: location.pathname });
                gtag('config', 'UA-50122865-23', { page_path: location.pathname });
            }
        });

        home.init();
        introduction.init();
        cast.init();
        news.init();
        pokemon.init();
        music.init();
        pronote.init();

        Barba.Prefetch.init();
        Barba.Pjax.start();
    };

    return {
        init: init
    };
})();
