'use strict';

/**
 * library
 */
import imagesLoaded from 'imagesloaded';
imagesLoaded.makeJQueryPlugin($);

/**
 * module
 */
import { pjax } from './module/_pjax';
import { disabled } from './module/_disabled';
import { fitimg } from './module/_fitimg';
import { anchor } from './module/_anchor';
import { nav } from './module/_nav';
import { hover } from './module/_hover';
import { modal } from './module/_modal';
import { trailer } from './module/_trailer';

/**
 * main
 */
const main = (() => {
    /**
     * loaded
     */
    const loaded = () => {
        setTimeout(function() {
            $('body').addClass('is-loaded');
            $('.layout-content__inner').addClass('is-start');
        }, 500);
    };

    /**
     * init
     */
    const init = () => {
        modal.init();
        trailer.init();
        pjax.init();
        disabled.init();
        fitimg.init();
        anchor.init();
        nav.init();
        hover.init();
    };

    // public
    return {
        init: init,
        loaded: loaded
    };
})();

$(() => {
    main.init();

    $('body')
        .imagesLoaded()
        .done(instance => {
            main.loaded();
        })
        .fail(() => {
            main.loaded();
        });
});
