'use strict';

import SimpleBar from 'simplebar';

import { fitimg } from './_fitimg';
import { anchor } from './_anchor';
import { hover } from './_hover';
import { modal } from './_modal';

export const page = (() => {
    const isSkip = () => {
        const referrer = document.referrer;
        const domain = document.domain;

        if (referrer.indexOf(domain) > -1) {
            return true;
        } else {
            return false;
        }
    };

    const publishTopics = data => {
        $.each(data, (index, value) => {
            let html = '';

            html += '<li class="news-list__item">';
            html += '<p class="news-list__date">' + value.date + '</p>';
            html += '<p class="news-list__txt">';
            html += '<a href="' + value.url_pc + '">' + value.title + '</a>';
            html += '</p>';
            html += '</li>';

            $('#feed').append(html);
        });
    };

    const init = () => {
        fitimg.init();
        anchor.init();
        hover.init();
        modal.init();

        if (!$('body').hasClass('is-changed')) {
            // 初回表示の時
            if ($('body').hasClass('is-home')) {
                if (!isSkip()) {
                    $('.js-auto-play').trigger('click');
                } else {
                    $('body').addClass('is-start-blink');
                }
            }

            $.ajax({
                url: '/news.json',
                type: 'GET',
                dataType: 'json'
            })
                .done(data => {
                    publishTopics(data);
                })
                .fail((jqXHR, textStatus, errorThrown) => {
                    console.log('XMLHttpRequest : ' + jqXHR.status);
                    console.log('textStatus : ' + textStatus);
                    console.log('errorThrown : ' + errorThrown);
                });

            new SimpleBar($('.js-scroll')[0], {
                autoHide: false
            });

            $('.js-menu').each((index, element) => {
                $(element).on('click', () => {
                    $('.js-menu.is-active').removeClass('is-active');
                    $(element).addClass('is-active');
                });
            });
        } else {
            // 別ページから遷移の時
            if ($('body').hasClass('is-home')) {
                $('body').addClass('is-start-blink');
            }
        }
    };

    const home = () => {
        init();

        const num_down = $('.hero-logo__down').length;
        const max_down = 4;
        let now_down = 0;
        let next_down = 0;

        let time = 0;

        const getNext = () => {
            next_down = Math.floor(Math.random() * num_down);

            if (now_down === next_down) {
                getNext();
            } else {
                now_down = next_down;
            }
        };

        const doBlink = () => {
            const $down = $('.hero-logo__down:eq(' + now_down + ')');
            let is_end = false;

            $down
                .css('animation-delay', '0s')
                .addClass('is-blink')
                .on('animationend', () => {
                    if (!is_end) {
                        is_end = true;
                        const delay = Math.floor(Math.random() * 10) / 10;
                        console.log(delay);
                        $down.css('animation-delay', delay + 's').removeClass('is-blink');
                    }
                });
        };

        const startBlink = () => {
            time++;

            if (time >= 1000) {
                const num = Math.floor(Math.random() * max_down);

                for (let i = 0; i < num; i++) {
                    setTimeout(() => {
                        getNext();
                        doBlink();
                    }, i * 200);
                }

                time = 0;
            }

            requestAnimationFrame(() => {
                startBlink();
            });
        };

        requestAnimationFrame(() => {
            startBlink();
        });
    };

    const introduction = () => {
        init();
    };

    const cast = () => {
        init();

        $('.js-comment').each((index, element) => {
            const $this = $(element);

            $this.on('click', () => {
                $('.comment.is-active').removeClass('is-active');
                $('.comment:eq(' + index + ')').addClass('is-active');
            });
        });
    };

    const news = () => {
        init();

        const url = '//movie-news.jp/meitantei-pikachu/';

        const getQuery = query => {
            const url = window.location.search;
            const hash = url.slice(1).split('&');
            let pair;
            let pairs = [];

            for (let i = 0; i < hash.length; i++) {
                pair = hash[i].split('=');
                pairs.push(pair[0]);
                pairs[pair[0]] = pair[1];
            }

            return pairs[query];
        };

        if (getQuery('page')) {
            $('#loader')[0].contentWindow.location.replace(url + getQuery('page'));
        }
    };

    const pokemon = () => {
        init();
    };

    const music = () => {
        init();
    };

    const pronote = () => {
        init();

        $('.js-toggle-pronote').each((index, element) => {
            const $this = $(element);

            $this.on('click', () => {
                $this.toggleClass('is-open');
                $this.next('.list__body').slideToggle();
            });
        });
    };

    return {
        home: home,
        introduction: introduction,
        cast: cast,
        news: news,
        pokemon: pokemon,
        music: music,
        pronote: pronote
    };
})();
