'use strict';

/**
 * library
 */
import objectFitImages from 'object-fit-images';

/**
 * fitimg
 */
export const fitimg = (() => {
    const init = () => {
        const $fitImage = $('.js-fit-image');

        objectFitImages($fitImage);
    };

    return {
        init: init
    };
})();
